(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-slug.js') >= 0) return;  svs.modules.push('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-slug.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const logger = svs.core.log.getLogger('marketplace:redirect');
const {
  url
} = svs.utils;
class RedirectToSlug extends React.Component {
  componentDidMount() {
    this.handleRedirect();
  }
  componentDidUpdate(prevProps) {
    if (['encodedTeamName', 'name', 'slug'].some(prop => this.props[prop] !== prevProps[prop])) {
      this.handleRedirect();
    }
  }
  handleRedirect() {
    const {
      additionalPath,
      history,
      id,
      name,
      prePath,
      slug,
      location
    } = this.props;
    const encodedTeamName = "-".concat(url.encode(name));
    if (name && slug !== encodedTeamName) {
      const redirectPath = prePath + id + encodedTeamName + additionalPath;
      logger.info("Expected ".concat(redirectPath, " but got ").concat(history.location.pathname));
      history.replace(_objectSpread(_objectSpread({}, location), {}, {
        pathname: redirectPath
      }));
    }
  }
  render() {
    return null;
  }
}
RedirectToSlug.defaultProps = {
  additionalPath: ''
};
setGlobal('svs.components.marketplace.redirectToSlug.RedirectToSlug', RedirectToSlug);

 })(window);