(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-team-slug-container.js') >= 0) return;  svs.modules.push('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-team-slug-container.js');

'use strict';

const {
  selectors
} = svs.components.marketplaceData;
const {
  RedirectToSlug,
  helpers
} = svs.components.marketplace.redirectToSlug;
const mapStateToProps = (state, _ref) => {
  var _selectors$teams$sele;
  let {
    match: {
      params: {
        groupId,
        teamPathName,
        retailerPathName,
        additionalPath
      },
      path
    }
  } = _ref;
  return {
    additionalPath,
    name: (_selectors$teams$sele = selectors.teams.selectTeam(state, {
      id: groupId
    })) === null || _selectors$teams$sele === void 0 ? void 0 : _selectors$teams$sele.name,
    id: Number(groupId),
    prePath: helpers.getPathBeforeParam(path, ':groupId'),
    slug: teamPathName || retailerPathName || ''
  };
};
setGlobal('svs.components.marketplace.redirectToSlug.RedirectToTeamSlugContainer', ReactRedux.connect(mapStateToProps)(RedirectToSlug));

 })(window);