(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-game-template-container.js') >= 0) return;  svs.modules.push('/components/marketplace/redirect-to-slug/assets/javascripts/redirect-to-game-template-container.js');

'use strict';

const _excluded = ["id", "slug"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  RedirectToSlug,
  helpers
} = svs.components.marketplace.redirectToSlug;
const mapStateToProps = (_ref2, _ref) => {
  let {
    GameTemplates: {
      Templates
    }
  } = _ref2;
  let {
      match: {
        params: {
          id,
          slug
        },
        path
      },
      groupId
    } = _ref,
    params = _objectWithoutProperties(_ref.match.params, _excluded);
  return {
    id: Number(id),
    name: (((Templates[groupId] || {}).data || []).find(t => t.id === Number(id)) || {}).name,
    prePath: helpers.getPathBeforeParam(path, ':id').split('/').map(part => {
      if (part.length === 0) {
        return part;
      }
      const paramRegEx = /(\w+)/;
      const [paramName] = part.slice(1).match(paramRegEx);
      if (!params[paramName]) {
        return part;
      }
      return part.replace(/^:/, '').replace(/\(([^)]+)\)/, '').replace(paramRegEx, params[paramName]);
    }).join('/'),
    slug
  };
};
setGlobal('svs.components.marketplace.redirectToSlug.RedirectToGameTemplateSlugContainer', ReactRedux.connect(mapStateToProps)(RedirectToSlug));

 })(window);