(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/redirect-to-slug/assets/javascripts/helpers/get-path-before-param.js') >= 0) return;  svs.modules.push('/components/marketplace/redirect-to-slug/assets/javascripts/helpers/get-path-before-param.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getPathBeforeParam = (path, param) => {
  const matches = path.match(new RegExp(param, 'g'));
  if (matches === null) {
    throw Error("No matches found for param '".concat(param, "' in path '").concat(path, "'."));
  }
  if (matches.length > 1) {
    throw Error("Multiple matches for param '".concat(param, "' found: ").concat(matches.join(', ')));
  }
  return path.split('/').reduce((acc, val) => {
    if (acc.done) {
      return acc;
    }
    if (val.indexOf(param) > -1) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        done: true
      });
    }
    return _objectSpread(_objectSpread({}, acc), {}, {
      path: acc.path.concat(val, '/')
    });
  }, {
    done: false,
    path: ''
  }).path;
};
setGlobal('svs.components.marketplace.redirectToSlug.helpers.getPathBeforeParam', getPathBeforeParam);

 })(window);